#navbar-items {
  display: flex;
}
#sidebar {
  display: none;
  background-color: black;
}

.hamburger {
  display: none;
}
#navbar-items.hide {
  display: none;
}

@media only screen and (max-width: 1024px) {
  .hamburger {
    display: block;
    cursor: pointer;
    z-index: 4;
  }
  #navbar-items {
    display: none;
  }
  #sidebar {
    z-index: 1;
    width: 70vw;
    height: 100vh;
    display: none;
    position: fixed;
    top: 0;
    right: -70vw;
  }

  #sidebar.active {
    z-index: 2;
    width: 70vw;
    height: 102vh;

    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 4;
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    transform: translateX(-70vw);
    box-shadow: -30vw 0px 0px 0px rgba(0, 0, 0, 0.3);
  }
  #sidebar-footer {
    margin-bottom: 10vh;
    z-index: 5;
  }
  .hamburger.active .bar:nth-child(1) {
    transform: translateY(4px) rotate(45deg);
  }

  .hamburger.active .bar:nth-child(2) {
    transform: translateY(-4px) rotate(-45deg);
  }
  .bar {
    display: block;
    width: 25px;
    height: 3px;
    margin: 5px auto;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background-color: white;
  }

  .navigators {
    width: 80%;
    margin: 0 10%;
    margin-top: 4vh;
  }
  #nav-p {
    font-size: 0.6rem;
  }
  .sidebar-row-1 {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
  }
  .sidebar-social-icons-row {
    display: flex;
    margin: 20px 0;
    width: 100%;
    justify-content: space-evenly;
  }
  #sidebar-logo {
    height: 30px;
  }
  hr.solid {
    border-top: 1px solid #e4e2ec;
    width: 35%;
    margin: 0;
  }
  .sidebar-footer-icon {
    width: 26px;
    height: 26px;
  }
}

@media only screen and (max-width: 600px) {
  #nav-p {
    font-size: 0.7rem;
    margin: 0;
    padding: 0;
  }
}
