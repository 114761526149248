@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap);
:root {
  --width-sm: 600px;
  --width-md: 1100px;
  --width-lg: 1440px;
  --primary-color: #ffb800;
  --secondary-color: #fefefe;
}

h1 h2 h3 p {
  padding: 0;
  margin: 0;
}

html {
  scroll-behavior: smooth;
  /* overflow: hidden; */
  background: black;
}

a {
  color: #ffb800;
  color: var(--primary-color);
}

#root {
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-appearance: none;
  appearance: none;
  color: #fff;
}
#turn {
  display: none;
}

.row {
  display: flex;
}
.col {
  display: flex;
  flex-direction: column;
}

.section {
  width: 100%;
  height: 100vh;
  /* padding: 5vh 5vw; */
}
.primary-title-style {
  font-weight: 700;
  font-size: 3.5rem;
  color: #ffb800;
  color: var(--primary-color);
}
.secondary-title-style {
  font-weight: 700;
  font-size: 3.5rem;
  color: #fefefe;
  color: var(--secondary-color);
}

@media screen and (min-width: 1024px) and (orientation: landscape) {
  .scroll-container {
    height: 100vh;
    overflow-y: scroll;
    -ms-scroll-snap-type: y mandatory;
        scroll-snap-type: y mandatory;
    scroll-behavior: smooth;
  }

  .section {
    scroll-snap-align: start;
    position: relative;
  }
}

/* (A) WRONG ORIENTATION - SHOW MESSAGE HIDE CONTENT */
@media screen and (max-width: 1024px) and (orientation: landscape) {
  #turn {
    display: block;
  }
  #root {
    display: none;
  }
}

/* (B) CORRECT ORIENTATION - SHOW CONTENT HIDE MESSAGE */
@media screen and (max-width: 1024px) and (orientation: portrait) {
  #turn {
    display: none;
  }
  #root {
    display: block;
  }
  .section {
    height: auto;
  }
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
a {
  text-decoration: none;
  padding: 0;
  display: flex;
}
.scroll-lock {
  overflow: hidden;
  position: relative;
  height: 100%;
}

a:hover {
  text-decoration: none;
  cursor: pointer;
}
*:focus {
  outline: 0 !important;
}

::-webkit-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-family: "Poppins", sans-serif;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-family: "Poppins", sans-serif;
  opacity: 1; /* Firefox */
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-family: "Poppins", sans-serif;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-family: "Poppins", sans-serif;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  font-family: "Poppins", sans-serif;
}
.slantline {
  height: 100px;
}

/* Media Queries */

@media screen and (max-width: 1300px) {
  .primary-title-style {
    font-size: 3rem;
    line-height: 3.5rem;
  }
  .secondary-title-style {
    font-size: 3rem;
    line-height: 3.5rem;
  }
}
@media screen and (max-width: 1024px) {
  .slantline {
    display: none;
    height: 60px;
  }
}
@media screen and (max-width: 600px) {
  .primary-title-style {
    font-size: 2rem;
  }
  .secondary-title-style {
    font-size: 2rem;
  }
  .section {
    height: auto;
  }
}
@media screen and (max-width: 340px) {
  .slantline {
    display: none;
    height: 60px;
  }
}


#header-sec {
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
}

.logo {
  height: 40px;
}

#navbar {
  width: 85%;
  margin: auto;
  padding-top: 5vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-wrapper {
  position: relative;
  z-index: 1;
}

.header-text {
  font-style: normal;
  font-weight: 700;
  font-size: 5rem;
  line-height: 6.5rem;
}

#navbar a {
  text-decoration: none;
  padding: 0 15px;
}

#navbar-items p {
  font-size: 1.2rem;
  color: #fff;
}

#illustration-container {
  display: flex;
  /* background-color: #fff5dc; */
  /* height: 80vh; */
  width: 100%;
  bottom: 0;
  /* position: absolute; */
  align-items: center;
  justify-content: end;
}

#illustration-container .illustration {
  width: 90%;
  position: relative;
  z-index: 1;
}

.header-container {
  padding: 0 8vw;
  margin: 2vh 0;
  display: flex;
  justify-content: space-evenly;
}

#header-sec input {
  width: 100%;
  padding: 16px 20px;
  box-sizing: border-box;
  background-color: #2d2a66;
  color: white;
  border: none;
  border-radius: 50px;
  font-size: 1rem;
}
#header-sec input:focus {
  outline: none !important;
}

::-webkit-input-placeholder {
  color: #8c97ac;
}

:-ms-input-placeholder {
  color: #8c97ac;
}

::placeholder {
  color: #8c97ac;
}

#header-content-box {
  z-index: 1;
  position: relative;
  width: 43vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#header-illustration-box {
  z-index: 1;
  position: relative;
  width: 43vw;
}

.content-glow {
  position: absolute;
  top: -5vh;
  width: 43vw;
  height: 43vw;
  left: -2vw;
  border-radius: 50%;
  /* background: red; */
  z-index: 1;
  background: linear-gradient(
    225.17deg,
    rgba(64, 0, 255, 0.2) 14.42%,
    rgba(130, 0, 255, 0.2) 36.17%,
    rgba(97, 0, 255, 0.2) 58.37%
  );
  -webkit-filter: blur(129.597px);
          filter: blur(129.597px);
  -webkit-transform: rotate(-29.54deg);
          transform: rotate(-29.54deg);
}

#header-content-box p {
  font-size: 1.5rem;
  line-height: 3rem;
  font-weight: 400;
  color: #b5c2ff;
}

.store-row {
  margin-top: 8vh;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.store-btn {
  padding: 12px 0;
  background-color: black;
  width: 32%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 30px;
  box-shadow: 0px 0px 25px rgba(125, 41, 205, 0.3);
}
#chrome-ext-img {
  height: 90%;
}

.waitlist-row {
  margin-top: 4rem;
  display: flex;
  justify-content: space-between;
}

.waitlist-row div:first-child {
  width: 60%;
}
.waitlist-row div:last-child {
  width: 30%;
}

#header-sec h1 {
  font-size: 5rem;
  font-weight: 700;
}

/* Media Queries */

@media screen and (max-width: 1300px) and (orientation: portrait) {
  #navbar-items p {
    font-size: 0.9rem;
  }
  #header-sec h1 {
    font-size: 3rem;
  }
  /* #header-sec p {
        font-size: 1.2rem;
    } */
  #header-sec ::-webkit-input-placeholder {
    font-size: 0.9rem;
  }
  #header-sec :-ms-input-placeholder {
    font-size: 0.9rem;
  }
  #header-sec ::placeholder {
    font-size: 0.9rem;
  }
}
@media screen and (max-width: 1300px) and (orientation: landscape) {
  .header-container {
    margin-top: 15vh;
  }
  .header-text {
    font-size: 4.5rem;
  }
}

@media screen and (max-width: 1024px) {
  #header-sec {
    background-image: none;
    height: auto;
  }

  .logo {
    height: 60px;
  }

  #header-illustration-box {
    width: 100%;
  }
  #illustration-container {
    width: 80vw;
    /* height: 90vh; */
    left: 10%;
    bottom: 0;
    border-top-left-radius: 40vw;
    border-top-right-radius: 40vw;
  }
  #header-content-box {
    width: 100%;
    text-align: center;
  }
  .header-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  #funance-row {
    justify-content: center;
  }

  #illustration-container .illustration {
    width: 90%;
    z-index: 1;
  }

  .waitlist-row div:first-child {
    width: 100%;
  }
  .waitlist-row div:last-child {
    width: 100%;
  }

  .store-row {
    width: 75%;
    margin-top: 2vh;
    justify-content: space-between;
  }
  .store-btn {
    width: 45%;
  }
  #chrome-extension-btn {
    display: none;
  }

  .header-container {
    margin: auto;
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;
  }

  #header-sec input {
    width: 100%;
    padding: 20px 20px;
    margin: 8px 0 30px 0;
    box-sizing: border-box;
    background-color: white;
    border: none;
    border-radius: 20px;
    font-size: 1rem;
  }
  #header-sec input:focus {
    outline: none !important;
  }

  ::-webkit-input-placeholder {
    color: #8c97ac;
  }

  :-ms-input-placeholder {
    color: #8c97ac;
  }

  ::placeholder {
    color: #8c97ac;
  }

  .waitlist-row {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  #header-sec h1 {
    font-size: 2.6rem;
  }

  #header-sec p {
    font-size: 1rem;
    line-height: 2.2rem;
    color: white;
  }
}

@media screen and (max-width: 600px) {
  #navbar a {
    text-decoration: none;
    padding: 15px 0;
  }
  .store-row {
    width: 100%;
    justify-content: space-between;
  }

  #header-content-box {
    width: 100%;
    text-align: left;
  }
  #funance-row {
    justify-content: left;
  }
  #header-illustration-box {
    width: 100%;
  }

  .header-text {
    font-style: normal;
    font-weight: 700;
    font-size: 2.8rem;
    line-height: 4rem;
  }
  .logo {
    height: 30px;
  }

  #illustration-container {
    width: 80vw;
    height: auto;
    border-top-left-radius: 40vw;
    border-top-right-radius: 40vw;
    /* padding-top: 20px; */
    /* background: linear-gradient(#fff1cc, #fffaed); */
    align-items: flex-start;
  }

  #illustration-container .illustration {
    width: 100%;
    height: 50%;
    object-fit: scale-down;
    z-index: 1;
  }

  .waitlist-row div:first-child {
    width: 100%;
  }
  .waitlist-row div:last-child {
    width: 100%;
  }

  .header-container {
    flex-direction: column-reverse;
    margin-top: 8vh;
    justify-content: center;
  }

  #header-sec input {
    width: 100%;
    padding: 16px 20px;
    margin: 8px 0 30px 0;
    box-sizing: border-box;
    background-color: white;
    border: none;
    border-radius: 50px;
    font-size: 1rem;
  }

  .waitlist-row {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .store-btn {
    width: 48%;
  }
  #header-sec h1 {
    font-size: 2rem;
  }

  #header-sec p {
    font-size: 1rem;
    line-height: 1.5rem;
    color: white;
  }
}

@media screen and (max-width: 340px) {
  #header-sec {
    height: 150vh;
  }

  .store-btn {
    width: 48%;
  }
}

#navbar-items {
  display: flex;
}
#sidebar {
  display: none;
  background-color: black;
}

.hamburger {
  display: none;
}
#navbar-items.hide {
  display: none;
}

@media only screen and (max-width: 1024px) {
  .hamburger {
    display: block;
    cursor: pointer;
    z-index: 4;
  }
  #navbar-items {
    display: none;
  }
  #sidebar {
    z-index: 1;
    width: 70vw;
    height: 100vh;
    display: none;
    position: fixed;
    top: 0;
    right: -70vw;
  }

  #sidebar.active {
    z-index: 2;
    width: 70vw;
    height: 102vh;

    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 4;
    transition: all 0.5s ease-in-out;
    -webkit-transform: translateX(-70vw);
            transform: translateX(-70vw);
    box-shadow: -30vw 0px 0px 0px rgba(0, 0, 0, 0.3);
  }
  #sidebar-footer {
    margin-bottom: 10vh;
    z-index: 5;
  }
  .hamburger.active .bar:nth-child(1) {
    -webkit-transform: translateY(4px) rotate(45deg);
            transform: translateY(4px) rotate(45deg);
  }

  .hamburger.active .bar:nth-child(2) {
    -webkit-transform: translateY(-4px) rotate(-45deg);
            transform: translateY(-4px) rotate(-45deg);
  }
  .bar {
    display: block;
    width: 25px;
    height: 3px;
    margin: 5px auto;
    transition: all 0.3s ease-in-out;
    background-color: white;
  }

  .navigators {
    width: 80%;
    margin: 0 10%;
    margin-top: 4vh;
  }
  #nav-p {
    font-size: 0.6rem;
  }
  .sidebar-row-1 {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
  }
  .sidebar-social-icons-row {
    display: flex;
    margin: 20px 0;
    width: 100%;
    justify-content: space-evenly;
  }
  #sidebar-logo {
    height: 30px;
  }
  hr.solid {
    border-top: 1px solid #e4e2ec;
    width: 35%;
    margin: 0;
  }
  .sidebar-footer-icon {
    width: 26px;
    height: 26px;
  }
}

@media only screen and (max-width: 600px) {
  #nav-p {
    font-size: 0.7rem;
    margin: 0;
    padding: 0;
  }
}

#feature-sec {
  height: 100vh;
}
#brand-row {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 5vh;
}
#and-more {
  font-weight: 700;
  font-size: 24px;
  line-height: 31px;
  text-transform: lowercase;
  color: #b5c2ff;
}

#feature-posts-wrapper-style {
  justify-content: center;
  flex-direction: row;
  align-items: center;
  height: 70vh;
  padding: 5vh 0;
}

#feat-img-container {
  width: 80%;
  padding-right: 5vw;
}
#brand-col {
  text-align: center;
}
.brand-header {
  font-weight: 600;
  font-size: 1.6rem;
}
/* Media Queries */

@media screen and (max-width: 1300px) {
}
@media screen and (max-width: 1024px) {
  #brand-col {
    padding: 0 5vw;
  }
  #brand-row {
    margin-top: 2vh;
    justify-content: space-evenly;
  }
  #feature-posts-wrapper-style {
    height: auto;
  }
}
@media screen and (max-width: 600px) {
  #feature-posts-wrapper-style {
    flex-direction: column;
    height: auto;
  }
  #feat-img-container {
    width: 85%;
    padding: 0 5vw;
  }
  #brand-col {
    margin: 5vh 0;
  }
  #brand-row {
    display: flex;
    flex-wrap: wrap;
    margin-top: 2vh;
  }
  .brand-header {
    font-size: 1.5rem;
  }

  #and-more {
    font-size: 0.9rem;
    line-height: 1.1rem;
  }

  .brand-item {
    margin: 20px 0px;
    height: 50px;
    text-align: center;
  }
  .brand-item img {
    height: 50px;
    width: 100px;
    object-fit: contain;
  }
}

#contact-sec {
  margin: 0;
  /* padding: 0 5vw; */
}
#feedback-sec {
  height: 75vh;
  position: relative;
  box-sizing: border-box;
  padding: 5vh 5vw;
  /* background-color: #ecfcff; */
  text-align: center;
  align-items: center;
  z-index: 1;
}

#feedback-sec h1 {
  font-size: 2rem;
}

#feedback-commas {
  position: absolute;
  top: 2.5vh;
  left: 45vw;
  z-index: -1;
  width: 9vw;
}

#contact-form-container {
  width: 55vw;
  display: inline-block;
}
#message-area {
  box-sizing: border-box;
  resize: none;
  padding: 30px;
  height: 32vh;
  width: 100%;
  color: white;
  font-size: 1rem;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.25);
  border-radius: 28px;
}

#message-area:focus {
  outline: none !important;
}

.row-1-input {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 2vh;
}

.row-2-input {
  margin: 30px 0;
}

#contact-form input {
  width: 100%;
  padding: 22px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  font-size: 1rem;
  background: rgba(255, 255, 255, 0.1);
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.25);
  border-radius: 40px;
}

@media screen and (max-width: 1300px) {
  #feedback-sec h1 {
    font-size: 2rem;
  }
  #contact-form input {
    padding: 15px 20px;
  }
}
@media screen and (max-width: 1024px) {
  #feedback-sec {
    height: auto;
  }
  #message-area {
    height: 25vh;
  }

  #feedback-commas {
    left: 43vw;
    width: 14vw;
  }
}
@media screen and (max-width: 600px) {
  #feedback-sec {
    height: auto;
    position: relative;
    /* background-color: #ecfcff; */
    text-align: center;
    align-items: center;
  }

  #feedback-sec h1 {
    font-size: 1.5rem;
  }
  #feedback-commas {
    left: 40vw;
    width: 20vw;
  }

  #contact-form-container {
    width: 90vw;
    display: inline-block;
  }

  #message-area {
    box-sizing: border-box;
    resize: none;
    padding: 30px;
    height: 35vh;
    width: 100%;
    font-size: 1rem;
  }

  #message-area:focus {
    outline: none !important;
  }

  .row-1-input {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    margin-top: 5vh;
  }

  .row-2-input {
    position: relative;
    top: 30px;
    margin-bottom: 7vh;
  }

  #contact-form input {
    width: 100%;
    padding: 22px 20px;
    margin: 8px 0;
    box-sizing: border-box;
    font-size: 1rem;
  }
}

#footer {
  padding: 20px 5vw;
  margin-top: 5vh;
  height: 20vh;
}

.footer-row-1 {
  display: flex;
  justify-content: space-between;
}

.footer-links {
  cursor: pointer;
  max-width: 30%;
  margin: 0 10px 0 10px;
  word-wrap: break-word;
  text-align: center;
}
#footer-wrapper-1 {
  display: flex;
  width: 40%;
  align-items: center;
  justify-content: space-between;
}

#footer-wrapper-2 {
  display: flex;
  width: 40%;
  align-items: center;
  justify-content: space-between;
}

.footer-logo-img {
  margin-top: 0;
  height: 40px;
  margin-left: 0;
}

.footer-social-icons {
  display: flex;
  width: 50%;
  justify-content: space-between;
}

.footer-heart-icon {
  height: 12px;
}

.footer-icon {
  height: 30px;
  margin-top: 0px;
  display: inline-block;
  vertical-align: middle;
  -webkit-filter: brightness(3);
          filter: brightness(3);
}

.divider {
  height: 24px;
  width: 2px;
  background-color: rgba(140, 151, 171, 0.4);
}

.footer-row-2 {
  display: flex;
  justify-content: space-between;
  margin-top: 3rem;
}

.footer-span-1 {
  color: #feb701;
}
.footer-span-2 {
  color: #feb701;
}

/* Media Queries */

@media screen and (max-width: 1024px) {
  #footer {
    padding: 5vh 2rem;
    height: auto;
  }
  .footer-row-1 {
    flex-direction: column;
  }

  #footer-wrapper-1 {
    width: 100%;
    margin-bottom: 5vh;
  }
  #footer-wrapper-2 {
    width: 70%;
  }
}
@media screen and (max-width: 600px) {
  #footer {
    margin-top: 0;
    padding: 100px 1.5rem;
  }

  .footer-row-1 {
    margin-bottom: 50px;
    flex-direction: column;
    justify-content: space-between;
  }

  #footer-wrapper-1 {
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    margin-bottom: 2vh;
    width: 100%;
  }

  #footer-wrapper-2 {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-weight: bold;
    align-items: baseline;
    margin: 0 0;
  }
  .footer-logo {
    justify-content: left;
    width: 100%;
  }
  .footer-social-icons {
    margin-top: 2rem;
    width: 90%;
  }
  .footer-logo-img {
    height: 36px;
  }

  .footer-row-2 {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 0;
  }

  .divider {
    display: none;
  }

  .footer-row-2 div {
    margin-top: 1rem;
  }

  .footer-links {
    padding: 10px 0;
    height: 24px;
    border: none;
  }
}

#team-sec {
  height: 100vh;
  width: 90%;
  margin: auto;
  text-align: center;
  box-sizing: border-box;
  padding: 5vh 0px;
  /* background-color: #fff; */
}

#team-sec h1 {
  font-size: 2rem;
}
.team-avatar {
  display: flex;
  width: 100%;
  justify-content: center;
  position: relative;
  top: -22%;
}

#team-base-circle {
  height: 170px;
  width: 170px;
  text-align: center;
  display: flex;
  justify-content: center;
}
#team-base-circle .nested-circle {
  height: 170px;
  width: 170px;
}

#team-base-circle .identity {
  position: relative;
  top: -15%;
}

.identity h1 {
  color: black;
}
#team-base-circle h1 {
  font-size: 1rem;
  margin: 0;
}
#team-base-circle p {
  font-size: 0.7rem;
  margin: 0 5px;
  padding-top: 2px;
  color: rgba(0, 213, 170, 1);
}

@media screen and (max-width: 1300px) {
  #team-sec h1 {
    font-size: 1.5rem;
  }

  #team-base-circle .identity h1 {
    font-size: 1rem;
  }
}

@media screen and (max-width: 1024px) {
  #team-sec {
    height: auto;
  }
}

#website-wrapper {
  background: linear-gradient(
    110deg,
    #13001c 9.09%,
    rgba(26, 3, 58, 0.921308) 20%,
    rgba(25, 7, 66, 0.834476) 31%,
    rgba(42, 12, 132, 0.73) 45%,
    rgba(61, 30, 152, 0.73) 57%,
    rgba(42, 12, 132, 0.73) 70%
  );
  position: relative;
  /* overflow: hidden; */
}

.animate-star {
  position: absolute;

  background: transparent;
  background-color: white;
  border-radius: 50%;
  opacity: 0.7;
  z-index: 1;
  /* box-shadow: $shadows-small; */
  -webkit-animation: animateStar 100s infinite linear;
          animation: animateStar 100s infinite linear;
}
.twinkle {
  -webkit-animation: twinkle 10s infinite ease-in-out;
          animation: twinkle 10s infinite ease-in-out;
}

@-webkit-keyframes animateStar {
  0% {
    -webkit-transform: scale(1) translate(0, 0px);
            transform: scale(1) translate(0, 0px);
    opacity: 0.5;
  }
  25% {
    -webkit-transform: scale(2) translate(100px, 50px);
            transform: scale(2) translate(100px, 50px);
    opacity: 0.2;
  }
  75% {
    -webkit-transform: scale(2) translate(-100px, -50px);
            transform: scale(2) translate(-100px, -50px);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1) translate(-100px, 0px);
            transform: scale(1) translate(-100px, 0px);
    opacity: 0.2;
  }
}

@keyframes animateStar {
  0% {
    -webkit-transform: scale(1) translate(0, 0px);
            transform: scale(1) translate(0, 0px);
    opacity: 0.5;
  }
  25% {
    -webkit-transform: scale(2) translate(100px, 50px);
            transform: scale(2) translate(100px, 50px);
    opacity: 0.2;
  }
  75% {
    -webkit-transform: scale(2) translate(-100px, -50px);
            transform: scale(2) translate(-100px, -50px);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1) translate(-100px, 0px);
            transform: scale(1) translate(-100px, 0px);
    opacity: 0.2;
  }
}
@-webkit-keyframes twinkle {
  0% {
    opacity: 1;
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  50% {
    opacity: 0;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  100% {
    opacity: 1;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
}
@keyframes twinkle {
  0% {
    opacity: 1;
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  50% {
    opacity: 0;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  100% {
    opacity: 1;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
}

@media screen and (max-width: 1024px) and (orientation: portrait) {
  #website-wrapper {
    overflow: hidden;
  }
}
@media screen and (max-width: 600px) {
  #website-wrapper {
    overflow: hidden;
  }
}

#mobile-mockup-2 {
  width: 40vw;
}

#game-container {
  justify-content: center;
  margin-top: 4rem;
  text-align: left;
  height: 70vh;
}
#game-sec {
  height: 100vh;
  padding: 10vh 0;
}

.game-header {
  margin-top: 8rem;
  height: 100%;
  width: 40vw;
}

.game-header h1 {
  font-size: 2rem;
}

.game-header p {
  font-size: 1.5rem;
  line-height: 3.5rem;
}

#feature-sec-2 {
  height: 100vh;
  box-sizing: border-box;
  padding: 5vh 0px;
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background: linear-gradient(rgba(254, 134, 0, 0.1), #fff); */
}

#circle-base {
  display: flex;
  margin: auto;
  justify-content: center;
  border-radius: 50%;
  width: 45vh;
  height: 45vh;
  -webkit-transform: rotateZ(0deg);
          transform: rotateZ(0deg);
}

/* Media Queries */

@media screen and (max-width: 1300px) {
  #feature-sec-2 h1 {
    font-size: 1.5rem;
    text-align: left;
  }
  .game-header p {
    font-size: 1.1rem;
    line-height: 2.6rem;
  }
  #circle-base {
    height: 45vh;
    width: 45vh;
  }
}
@media screen and (max-width: 1024px) {
  #game-sec {
    height: auto;
  }
  #game-container {
    justify-content: center;
    margin-top: auto;
    text-align: left;
    height: 50vh;
  }
  #feature-sec {
    height: auto;
  }
  #feature-sec-2 {
    height: auto;
  }
  .game-header h1 {
    font-size: 1.7rem;
  }
  .game-header p {
    font-size: 1.1rem;
    line-height: 2.6rem;
  }
  #mobile {
    border-radius: 1.5rem;
  }

  #circle-base {
    height: 30vw;
    width: 30vw;
  }
  #mobile {
    border-radius: 1.5rem;
    width: 15vw;
    height: 30vw;
  }
}
@media screen and (max-width: 600px) {
  #feature-sec-2 {
    padding: 8vh 0;
  }
  #feature-sec-2 h1 {
    text-align: center;
  }

  #mobile-mockup-2 {
    width: 100%;
  }

  #game-container {
    display: flex;
    flex-direction: column-reverse;
    height: auto;
    justify-content: space-evenly;
    margin: 2rem 0px 8vh 0px;
    padding: 0 20px 0 20px;
    text-align: center;
  }

  .game-header {
    margin-top: 4rem;
    width: 100%;
    text-align: left;
  }
  #game-container h1 {
    font-size: 2rem;
  }

  .game-header p {
    font-size: 1.25rem;
    line-height: 2.5rem;
  }

  #circle-base {
    width: 75vw;
    height: 75vw;
    -webkit-transform: rotateZ(0deg);
            transform: rotateZ(0deg);
  }
  #mobile {
    margin-top: 4rem;
    height: 75vw;
    width: 38vw;
    border-radius: 1.2rem;
    background-color: white;
    box-shadow: 40px 40px 100px 0px #7453001a;
  }
}

#learn-sec {
  height: 100vh;
  box-sizing: border-box;
  padding: 5vh 0;
}

#learn-container {
  justify-content: center;
  height: 70vh;
  margin-top: 5rem;
  text-align: left;
}

#learn-box {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

#mobile-img-1 {
  margin-right: 8vw;
}

#learn-box h1 {
  font-size: 1.7rem;
}
#learn-box p {
  font-size: 1.25rem;
  color: #575662;
}

#circle-base {
  display: flex;
  margin: auto;
  justify-content: center;
  border-radius: 50%;
  width: 45vh;
  height: 45vh;
  -webkit-transform: rotateZ(0deg);
          transform: rotateZ(0deg);
}

/* Media Queries */

@media screen and (max-width: 1300px) {
  #learn-sec h1 {
    font-size: 1.5rem;
  }
  #learn-box h1 {
    font-size: 1.2rem;
  }
  #learn-box p {
    font-size: 1.1rem;
  }
  #circle-base {
    height: 45vh;
    width: 45vh;
  }
  #mobile-img-1 {
    margin-right: 6vw;
  }
}
@media screen and (max-width: 1300px) and (orientation: landscape) {
  #learn-container {
    padding: 0 5vw;
  }
}
@media screen and (max-width: 1024px) {
  #learn-sec {
    height: auto;
    box-sizing: border-box;
    padding: 5vh 0;
  }
  #learn-heading {
    flex-wrap: wrap;
    margin-bottom: 4vh;
  }
  #mobile-img-1 {
    margin-right: 3vw;
  }

  #learn-container {
    /* flex-direction: column; */
    height: auto;
    padding: 0 5vw;
  }
  #learn-box h1 {
    font-size: 1.2rem;
  }
  #learn-box p {
    font-size: 0.9rem;
  }
  #mobile {
    border-radius: 1.5rem;
  }

  #circle-base {
    height: 30vw;
    width: 30vw;
  }
  #mobile {
    border-radius: 1.5rem;
    width: 15vw;
    height: 30vw;
  }
  #learn-box {
    width: 135%;
  }
}
@media screen and (max-width: 600px) {
  #learn-container {
    justify-content: space-evenly;
    flex-direction: column;
    padding: 0 5vw;
    height: auto;
    text-align: left;
  }

  #mobile-img-1 {
    margin-right: auto;
  }
  #mobile-mockup-1 {
    width: 100%;
  }

  #learn-box {
    width: 100%;
    /* height: 60vh; */
  }

  #learn-box h1 {
    font-size: 1.1rem;
  }
  #learn-box p {
    font-size: 0.9rem;
  }

  #upcoming {
    display: none;
  }

  #circle-base {
    width: 75vw;
    height: 75vw;
    -webkit-transform: rotateZ(0deg);
            transform: rotateZ(0deg);
  }
  #mobile {
    margin-top: 4rem;
    height: 75vw;
    width: 38vw;
    border-radius: 1.2rem;
    background-color: white;
    box-shadow: 40px 40px 100px 0px #7453001a;
  }
}

#investor-sec {
  padding: 5vh 0;
}

#investor-box {
  width: 90%;
  margin-bottom: 10vh;
}
#investor-box h1 {
  font-size: 2rem;
}

.investor-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#investor-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 25vh;
  padding: 20px;
}
#investor-column h1 {
  font-size: 1rem;
  color: black;
}
.investor-container {
  height: 140px;
  border-radius: 6vw;
  position: relative;
  object-fit: contain;
}
.investor-container .investor-img {
  height: 100%;
}

.investor-company {
  position: absolute;
  display: flex;
  top: 78%;
  right: -20%;
  background-color: white;
  align-items: center;
  padding: 10px 15px;
  border-radius: 20px;
}
.investor-company p {
  margin: 0;
  font-size: 0.7rem;
}

/* Customizing Carousel */

/* For Dev */
.sc-gKclnd.lcUbOd.rec.rec-dot.rec.rec-dot_active {
  width: 40px;
  height: 4px;
  border-radius: 2px;
  background-color: #00d5aa;
  box-shadow: none;
}
.sc-gKclnd.eaptOe.rec.rec-dot {
  width: 40px;
  height: 4px;
  border-radius: 2px;
  background-color: #e2e4e8;
  box-shadow: none;
}

/* For Prod */
.sc-gKclnd.bBjxWM.rec.rec-dot.rec.rec-dot_active {
  width: 40px;
  height: 4px;
  border-radius: 2px;
  background-color: #00d5aa;
  box-shadow: none;
}
.sc-gKclnd.gpDNSX.rec.rec-dot {
  width: 40px;
  height: 4px;
  border-radius: 2px;
  background-color: #e2e4e8;
  box-shadow: none;
}

/* Arrow Buttons */

/* For Dev */
.sc-bdvvtL.cNNJIt.rec.rec-arrow.rec.rec-arrow-left {
  position: relative;
  box-shadow: none;
  color: #e2e4e8;
  background-color: transparent;
}
.sc-bdvvtL.cNNJIt.rec.rec-arrow.rec.rec-arrow-right {
  position: relative;
  box-shadow: none;
  color: #e2e4e8;
  background-color: transparent;
}

.sc-bdvvtL.jRFJkP.rec.rec-arrow.rec.rec-arrow-right {
  position: relative;
  box-shadow: none;
  color: #00d5aa;
  background-color: transparent;
}
.sc-bdvvtL.jRFJkP.rec.rec-arrow.rec.rec-arrow-left {
  position: relative;
  box-shadow: none;
  color: #00d5aa;
  background-color: transparent;
}

.sc-bdvvtL.jRFJkP.rec.rec-arrow.rec.rec-arrow-right:hover {
  color: #2aad93;
}
.sc-bdvvtL.jRFJkP.rec.rec-arrow.rec.rec-arrow-left:hover {
  color: #2aad93;
}

/* For Prod */
.sc-bdvvtL.fVgyLU.rec.rec-arrow.rec.rec-arrow-right {
  position: relative;
  box-shadow: none;
  color: #00d5aa;
  background-color: transparent;
}
.sc-bdvvtL.fVgyLU.rec.rec-arrow.rec.rec-arrow-left {
  position: relative;
  box-shadow: none;
  color: #00d5aa;
  background-color: transparent;
}

.sc-bdvvtL.fViXLK.rec.rec-arrow.rec.rec-arrow-right {
  position: relative;
  box-shadow: none;
  color: #e2e4e8;
  background-color: transparent;
}
.sc-bdvvtL.fViXLK.rec.rec-arrow.rec.rec-arrow-left {
  position: relative;
  box-shadow: none;
  color: #e2e4e8;
  background-color: transparent;
}

/* Media Queries */
@media screen and (max-width: 1300px) {
  #mission-sec h1 {
    font-size: 1.5rem;
  }
  #investor-column h1 {
    font-size: 1rem;
  }
}
@media screen and (max-width: 1024px) {
  #mission-sec {
    height: auto;
  }
  #mission-sec-1 {
    height: auto;
  }

  #comma-box {
    left: 70%;
    top: -35%;
  }
  #comma-box img {
    height: 12vh;
  }

  #company-box {
    border-radius: 30px;
    height: 20vh;
    width: 80vw;
    margin-bottom: 20vh;
  }
  .investor-container {
    height: 22vh;
    border-radius: 6vw;
    position: relative;
    object-fit: contain;
  }
  /* For Dev */
  .sc-gKclnd.lcUbOd.rec.rec-dot.rec.rec-dot_active {
    width: 30px;
    height: 4px;
    border-radius: 2px;
    background-color: #00d5aa;
    box-shadow: none;
  }
  .sc-gKclnd.eaptOe.rec.rec-dot {
    width: 30px;
    height: 4px;
    border-radius: 2px;
    background-color: #e2e4e8;
    box-shadow: none;
  }

  /* For Prod */
  .sc-gKclnd.bBjxWM.rec.rec-dot.rec.rec-dot_active {
    width: 30px;
    height: 4px;
    border-radius: 2px;
    background-color: #00d5aa;
    box-shadow: none;
  }
  .sc-gKclnd.gpDNSX.rec.rec-dot {
    width: 30px;
    height: 4px;
    border-radius: 2px;
    background-color: #e2e4e8;
    box-shadow: none;
  }
}
@media screen and (max-width: 600px) {
  #mission-sec {
    position: relative;
    text-align: center;
    padding-top: 8vh;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  #mission-sec-1 {
    padding: 0px 0px;
  }
  .investor-container {
    height: 15vh;
  }

  #mission-main-header {
    font-size: 1rem;
  }
  .green-heart {
    display: none;
  }

  #company-box {
    width: 80%;
    height: 25vh;
    display: flex;
    flex-direction: column;
    margin: auto;
    margin-top: 20vh;
    margin-bottom: 28vh;
    padding: 20px 30px;
    position: relative;
    text-align: left;
    border-radius: 26px;
    background-color: #fffaed;
  }

  #company-box .left-column h1 {
    position: relative;
    top: 0;
    font-size: 0.8rem;
  }
  #company-box .right-column {
    width: 100%;
    position: relative;
    top: -20%;
  }

  #company-box .left-column {
    width: 60%;
  }
  #comma-box {
    left: 65%;
    top: -15%;
  }
  #comma-box img {
    height: 70px;
  }

  #company-box p {
    text-align: left;
    line-height: 1.4rem;
    font-size: 0.8rem;
    color: #575662;
  }

  #investor-box {
    width: 90%;
    text-align: center;
    margin-bottom: 0vh;
  }

  #investor-box h1 {
    font-size: 1.5rem;
    text-align: center;
  }

  .sc-gKclnd.lcUbOd.rec.rec-dot.rec.rec-dot_active {
    width: 10px;
    height: 2px;
    border-radius: 1px;
    background-color: #00d5aa;
    box-shadow: none;
  }
  .sc-gKclnd.eaptOe.rec.rec-dot {
    width: 10px;
    height: 2px;
    border-radius: 1px;
    background-color: #e2e4e8;
    box-shadow: none;
  }

  /* For Prod */
  .sc-gKclnd.bBjxWM.rec.rec-dot.rec.rec-dot_active {
    width: 10px;
    height: 2px;
    border-radius: 1px;
    background-color: #00d5aa;
    box-shadow: none;
  }
  .sc-gKclnd.gpDNSX.rec.rec-dot {
    width: 10px;
    height: 2px;
    border-radius: 1px;
    background-color: #e2e4e8;
    box-shadow: none;
  }
}

@media screen and (max-width: 340px) {
  #investor-box h1 {
    font-size: 1rem;
  }
}

