@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap");
:root {
  --width-sm: 600px;
  --width-md: 1100px;
  --width-lg: 1440px;
  --primary-color: #ffb800;
  --secondary-color: #fefefe;
}

h1 h2 h3 p {
  padding: 0;
  margin: 0;
}

html {
  scroll-behavior: smooth;
  /* overflow: hidden; */
  background: black;
}

a {
  color: var(--primary-color);
}

#root {
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: #fff;
}
#turn {
  display: none;
}

.row {
  display: flex;
}
.col {
  display: flex;
  flex-direction: column;
}

.section {
  width: 100%;
  height: 100vh;
  /* padding: 5vh 5vw; */
}
.primary-title-style {
  font-weight: 700;
  font-size: 3.5rem;
  color: var(--primary-color);
}
.secondary-title-style {
  font-weight: 700;
  font-size: 3.5rem;
  color: var(--secondary-color);
}

@media screen and (min-width: 1024px) and (orientation: landscape) {
  .scroll-container {
    height: 100vh;
    overflow-y: scroll;
    scroll-snap-type: y mandatory;
    scroll-behavior: smooth;
  }

  .section {
    scroll-snap-align: start;
    position: relative;
  }
}

/* (A) WRONG ORIENTATION - SHOW MESSAGE HIDE CONTENT */
@media screen and (max-width: 1024px) and (orientation: landscape) {
  #turn {
    display: block;
  }
  #root {
    display: none;
  }
}

/* (B) CORRECT ORIENTATION - SHOW CONTENT HIDE MESSAGE */
@media screen and (max-width: 1024px) and (orientation: portrait) {
  #turn {
    display: none;
  }
  #root {
    display: block;
  }
  .section {
    height: auto;
  }
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
a {
  text-decoration: none;
  padding: 0;
  display: flex;
}
.scroll-lock {
  overflow: hidden;
  position: relative;
  height: 100%;
}

a:hover {
  text-decoration: none;
  cursor: pointer;
}
*:focus {
  outline: 0 !important;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-family: "Poppins", sans-serif;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-family: "Poppins", sans-serif;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  font-family: "Poppins", sans-serif;
}
.slantline {
  height: 100px;
}

/* Media Queries */

@media screen and (max-width: 1300px) {
  .primary-title-style {
    font-size: 3rem;
    line-height: 3.5rem;
  }
  .secondary-title-style {
    font-size: 3rem;
    line-height: 3.5rem;
  }
}
@media screen and (max-width: 1024px) {
  .slantline {
    display: none;
    height: 60px;
  }
}
@media screen and (max-width: 600px) {
  .primary-title-style {
    font-size: 2rem;
  }
  .secondary-title-style {
    font-size: 2rem;
  }
  .section {
    height: auto;
  }
}
@media screen and (max-width: 340px) {
  .slantline {
    display: none;
    height: 60px;
  }
}
