#investor-sec {
  padding: 5vh 0;
}

#investor-box {
  width: 90%;
  margin-bottom: 10vh;
}
#investor-box h1 {
  font-size: 2rem;
}

.investor-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#investor-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 25vh;
  padding: 20px;
}
#investor-column h1 {
  font-size: 1rem;
  color: black;
}
.investor-container {
  height: 140px;
  border-radius: 6vw;
  position: relative;
  object-fit: contain;
}
.investor-container .investor-img {
  height: 100%;
}

.investor-company {
  position: absolute;
  display: flex;
  top: 78%;
  right: -20%;
  background-color: white;
  align-items: center;
  padding: 10px 15px;
  border-radius: 20px;
}
.investor-company p {
  margin: 0;
  font-size: 0.7rem;
}

/* Customizing Carousel */

/* For Dev */
.sc-gKclnd.lcUbOd.rec.rec-dot.rec.rec-dot_active {
  width: 40px;
  height: 4px;
  border-radius: 2px;
  background-color: #00d5aa;
  box-shadow: none;
}
.sc-gKclnd.eaptOe.rec.rec-dot {
  width: 40px;
  height: 4px;
  border-radius: 2px;
  background-color: #e2e4e8;
  box-shadow: none;
}

/* For Prod */
.sc-gKclnd.bBjxWM.rec.rec-dot.rec.rec-dot_active {
  width: 40px;
  height: 4px;
  border-radius: 2px;
  background-color: #00d5aa;
  box-shadow: none;
}
.sc-gKclnd.gpDNSX.rec.rec-dot {
  width: 40px;
  height: 4px;
  border-radius: 2px;
  background-color: #e2e4e8;
  box-shadow: none;
}

/* Arrow Buttons */

/* For Dev */
.sc-bdvvtL.cNNJIt.rec.rec-arrow.rec.rec-arrow-left {
  position: relative;
  box-shadow: none;
  color: #e2e4e8;
  background-color: transparent;
}
.sc-bdvvtL.cNNJIt.rec.rec-arrow.rec.rec-arrow-right {
  position: relative;
  box-shadow: none;
  color: #e2e4e8;
  background-color: transparent;
}

.sc-bdvvtL.jRFJkP.rec.rec-arrow.rec.rec-arrow-right {
  position: relative;
  box-shadow: none;
  color: #00d5aa;
  background-color: transparent;
}
.sc-bdvvtL.jRFJkP.rec.rec-arrow.rec.rec-arrow-left {
  position: relative;
  box-shadow: none;
  color: #00d5aa;
  background-color: transparent;
}

.sc-bdvvtL.jRFJkP.rec.rec-arrow.rec.rec-arrow-right:hover {
  color: #2aad93;
}
.sc-bdvvtL.jRFJkP.rec.rec-arrow.rec.rec-arrow-left:hover {
  color: #2aad93;
}

/* For Prod */
.sc-bdvvtL.fVgyLU.rec.rec-arrow.rec.rec-arrow-right {
  position: relative;
  box-shadow: none;
  color: #00d5aa;
  background-color: transparent;
}
.sc-bdvvtL.fVgyLU.rec.rec-arrow.rec.rec-arrow-left {
  position: relative;
  box-shadow: none;
  color: #00d5aa;
  background-color: transparent;
}

.sc-bdvvtL.fViXLK.rec.rec-arrow.rec.rec-arrow-right {
  position: relative;
  box-shadow: none;
  color: #e2e4e8;
  background-color: transparent;
}
.sc-bdvvtL.fViXLK.rec.rec-arrow.rec.rec-arrow-left {
  position: relative;
  box-shadow: none;
  color: #e2e4e8;
  background-color: transparent;
}

/* Media Queries */
@media screen and (max-width: 1300px) {
  #mission-sec h1 {
    font-size: 1.5rem;
  }
  #investor-column h1 {
    font-size: 1rem;
  }
}
@media screen and (max-width: 1024px) {
  #mission-sec {
    height: auto;
  }
  #mission-sec-1 {
    height: auto;
  }

  #comma-box {
    left: 70%;
    top: -35%;
  }
  #comma-box img {
    height: 12vh;
  }

  #company-box {
    border-radius: 30px;
    height: 20vh;
    width: 80vw;
    margin-bottom: 20vh;
  }
  .investor-container {
    height: 22vh;
    border-radius: 6vw;
    position: relative;
    object-fit: contain;
  }
  /* For Dev */
  .sc-gKclnd.lcUbOd.rec.rec-dot.rec.rec-dot_active {
    width: 30px;
    height: 4px;
    border-radius: 2px;
    background-color: #00d5aa;
    box-shadow: none;
  }
  .sc-gKclnd.eaptOe.rec.rec-dot {
    width: 30px;
    height: 4px;
    border-radius: 2px;
    background-color: #e2e4e8;
    box-shadow: none;
  }

  /* For Prod */
  .sc-gKclnd.bBjxWM.rec.rec-dot.rec.rec-dot_active {
    width: 30px;
    height: 4px;
    border-radius: 2px;
    background-color: #00d5aa;
    box-shadow: none;
  }
  .sc-gKclnd.gpDNSX.rec.rec-dot {
    width: 30px;
    height: 4px;
    border-radius: 2px;
    background-color: #e2e4e8;
    box-shadow: none;
  }
}
@media screen and (max-width: 600px) {
  #mission-sec {
    position: relative;
    text-align: center;
    padding-top: 8vh;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  #mission-sec-1 {
    padding: 0px 0px;
  }
  .investor-container {
    height: 15vh;
  }

  #mission-main-header {
    font-size: 1rem;
  }
  .green-heart {
    display: none;
  }

  #company-box {
    width: 80%;
    height: 25vh;
    display: flex;
    flex-direction: column;
    margin: auto;
    margin-top: 20vh;
    margin-bottom: 28vh;
    padding: 20px 30px;
    position: relative;
    text-align: left;
    border-radius: 26px;
    background-color: #fffaed;
  }

  #company-box .left-column h1 {
    position: relative;
    top: 0;
    font-size: 0.8rem;
  }
  #company-box .right-column {
    width: 100%;
    position: relative;
    top: -20%;
  }

  #company-box .left-column {
    width: 60%;
  }
  #comma-box {
    left: 65%;
    top: -15%;
  }
  #comma-box img {
    height: 70px;
  }

  #company-box p {
    text-align: left;
    line-height: 1.4rem;
    font-size: 0.8rem;
    color: #575662;
  }

  #investor-box {
    width: 90%;
    text-align: center;
    margin-bottom: 0vh;
  }

  #investor-box h1 {
    font-size: 1.5rem;
    text-align: center;
  }

  .sc-gKclnd.lcUbOd.rec.rec-dot.rec.rec-dot_active {
    width: 10px;
    height: 2px;
    border-radius: 1px;
    background-color: #00d5aa;
    box-shadow: none;
  }
  .sc-gKclnd.eaptOe.rec.rec-dot {
    width: 10px;
    height: 2px;
    border-radius: 1px;
    background-color: #e2e4e8;
    box-shadow: none;
  }

  /* For Prod */
  .sc-gKclnd.bBjxWM.rec.rec-dot.rec.rec-dot_active {
    width: 10px;
    height: 2px;
    border-radius: 1px;
    background-color: #00d5aa;
    box-shadow: none;
  }
  .sc-gKclnd.gpDNSX.rec.rec-dot {
    width: 10px;
    height: 2px;
    border-radius: 1px;
    background-color: #e2e4e8;
    box-shadow: none;
  }
}

@media screen and (max-width: 340px) {
  #investor-box h1 {
    font-size: 1rem;
  }
}
