#footer {
  padding: 20px 5vw;
  margin-top: 5vh;
  height: 20vh;
}

.footer-row-1 {
  display: flex;
  justify-content: space-between;
}

.footer-links {
  cursor: pointer;
  max-width: 30%;
  margin: 0 10px 0 10px;
  word-wrap: break-word;
  text-align: center;
}
#footer-wrapper-1 {
  display: flex;
  width: 40%;
  align-items: center;
  justify-content: space-between;
}

#footer-wrapper-2 {
  display: flex;
  width: 40%;
  align-items: center;
  justify-content: space-between;
}

.footer-logo-img {
  margin-top: 0;
  height: 40px;
  margin-left: 0;
}

.footer-social-icons {
  display: flex;
  width: 50%;
  justify-content: space-between;
}

.footer-heart-icon {
  height: 12px;
}

.footer-icon {
  height: 30px;
  margin-top: 0px;
  display: inline-block;
  vertical-align: middle;
  filter: brightness(3);
}

.divider {
  height: 24px;
  width: 2px;
  background-color: rgba(140, 151, 171, 0.4);
}

.footer-row-2 {
  display: flex;
  justify-content: space-between;
  margin-top: 3rem;
}

.footer-span-1 {
  color: #feb701;
}
.footer-span-2 {
  color: #feb701;
}

/* Media Queries */

@media screen and (max-width: 1024px) {
  #footer {
    padding: 5vh 2rem;
    height: auto;
  }
  .footer-row-1 {
    flex-direction: column;
  }

  #footer-wrapper-1 {
    width: 100%;
    margin-bottom: 5vh;
  }
  #footer-wrapper-2 {
    width: 70%;
  }
}
@media screen and (max-width: 600px) {
  #footer {
    margin-top: 0;
    padding: 100px 1.5rem;
  }

  .footer-row-1 {
    margin-bottom: 50px;
    flex-direction: column;
    justify-content: space-between;
  }

  #footer-wrapper-1 {
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    margin-bottom: 2vh;
    width: 100%;
  }

  #footer-wrapper-2 {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-weight: bold;
    align-items: baseline;
    margin: 0 0;
  }
  .footer-logo {
    justify-content: left;
    width: 100%;
  }
  .footer-social-icons {
    margin-top: 2rem;
    width: 90%;
  }
  .footer-logo-img {
    height: 36px;
  }

  .footer-row-2 {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 0;
  }

  .divider {
    display: none;
  }

  .footer-row-2 div {
    margin-top: 1rem;
  }

  .footer-links {
    padding: 10px 0;
    height: 24px;
    border: none;
  }
}
