#learn-sec {
  height: 100vh;
  box-sizing: border-box;
  padding: 5vh 0;
}

#learn-container {
  justify-content: center;
  height: 70vh;
  margin-top: 5rem;
  text-align: left;
}

#learn-box {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

#mobile-img-1 {
  margin-right: 8vw;
}

#learn-box h1 {
  font-size: 1.7rem;
}
#learn-box p {
  font-size: 1.25rem;
  color: #575662;
}

#circle-base {
  display: flex;
  margin: auto;
  justify-content: center;
  border-radius: 50%;
  width: 45vh;
  height: 45vh;
  transform: rotateZ(0deg);
}

/* Media Queries */

@media screen and (max-width: 1300px) {
  #learn-sec h1 {
    font-size: 1.5rem;
  }
  #learn-box h1 {
    font-size: 1.2rem;
  }
  #learn-box p {
    font-size: 1.1rem;
  }
  #circle-base {
    height: 45vh;
    width: 45vh;
  }
  #mobile-img-1 {
    margin-right: 6vw;
  }
}
@media screen and (max-width: 1300px) and (orientation: landscape) {
  #learn-container {
    padding: 0 5vw;
  }
}
@media screen and (max-width: 1024px) {
  #learn-sec {
    height: auto;
    box-sizing: border-box;
    padding: 5vh 0;
  }
  #learn-heading {
    flex-wrap: wrap;
    margin-bottom: 4vh;
  }
  #mobile-img-1 {
    margin-right: 3vw;
  }

  #learn-container {
    /* flex-direction: column; */
    height: auto;
    padding: 0 5vw;
  }
  #learn-box h1 {
    font-size: 1.2rem;
  }
  #learn-box p {
    font-size: 0.9rem;
  }
  #mobile {
    border-radius: 1.5rem;
  }

  #circle-base {
    height: 30vw;
    width: 30vw;
  }
  #mobile {
    border-radius: 1.5rem;
    width: 15vw;
    height: 30vw;
  }
  #learn-box {
    width: 135%;
  }
}
@media screen and (max-width: 600px) {
  #learn-container {
    justify-content: space-evenly;
    flex-direction: column;
    padding: 0 5vw;
    height: auto;
    text-align: left;
  }

  #mobile-img-1 {
    margin-right: auto;
  }
  #mobile-mockup-1 {
    width: 100%;
  }

  #learn-box {
    width: 100%;
    /* height: 60vh; */
  }

  #learn-box h1 {
    font-size: 1.1rem;
  }
  #learn-box p {
    font-size: 0.9rem;
  }

  #upcoming {
    display: none;
  }

  #circle-base {
    width: 75vw;
    height: 75vw;
    transform: rotateZ(0deg);
  }
  #mobile {
    margin-top: 4rem;
    height: 75vw;
    width: 38vw;
    border-radius: 1.2rem;
    background-color: white;
    box-shadow: 40px 40px 100px 0px #7453001a;
  }
}
