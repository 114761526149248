#contact-sec {
  margin: 0;
  /* padding: 0 5vw; */
}
#feedback-sec {
  height: 75vh;
  position: relative;
  box-sizing: border-box;
  padding: 5vh 5vw;
  /* background-color: #ecfcff; */
  text-align: center;
  align-items: center;
  z-index: 1;
}

#feedback-sec h1 {
  font-size: 2rem;
}

#feedback-commas {
  position: absolute;
  top: 2.5vh;
  left: 45vw;
  z-index: -1;
  width: 9vw;
}

#contact-form-container {
  width: 55vw;
  display: inline-block;
}
#message-area {
  box-sizing: border-box;
  resize: none;
  padding: 30px;
  height: 32vh;
  width: 100%;
  color: white;
  font-size: 1rem;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.25);
  border-radius: 28px;
}

#message-area:focus {
  outline: none !important;
}

.row-1-input {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 2vh;
}

.row-2-input {
  margin: 30px 0;
}

#contact-form input {
  width: 100%;
  padding: 22px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  font-size: 1rem;
  background: rgba(255, 255, 255, 0.1);
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.25);
  border-radius: 40px;
}

@media screen and (max-width: 1300px) {
  #feedback-sec h1 {
    font-size: 2rem;
  }
  #contact-form input {
    padding: 15px 20px;
  }
}
@media screen and (max-width: 1024px) {
  #feedback-sec {
    height: auto;
  }
  #message-area {
    height: 25vh;
  }

  #feedback-commas {
    left: 43vw;
    width: 14vw;
  }
}
@media screen and (max-width: 600px) {
  #feedback-sec {
    height: auto;
    position: relative;
    /* background-color: #ecfcff; */
    text-align: center;
    align-items: center;
  }

  #feedback-sec h1 {
    font-size: 1.5rem;
  }
  #feedback-commas {
    left: 40vw;
    width: 20vw;
  }

  #contact-form-container {
    width: 90vw;
    display: inline-block;
  }

  #message-area {
    box-sizing: border-box;
    resize: none;
    padding: 30px;
    height: 35vh;
    width: 100%;
    font-size: 1rem;
  }

  #message-area:focus {
    outline: none !important;
  }

  .row-1-input {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    margin-top: 5vh;
  }

  .row-2-input {
    position: relative;
    top: 30px;
    margin-bottom: 7vh;
  }

  #contact-form input {
    width: 100%;
    padding: 22px 20px;
    margin: 8px 0;
    box-sizing: border-box;
    font-size: 1rem;
  }
}
