#header-sec {
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
}

.logo {
  height: 40px;
}

#navbar {
  width: 85%;
  margin: auto;
  padding-top: 5vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-wrapper {
  position: relative;
  z-index: 1;
}

.header-text {
  font-style: normal;
  font-weight: 700;
  font-size: 5rem;
  line-height: 6.5rem;
}

#navbar a {
  text-decoration: none;
  padding: 0 15px;
}

#navbar-items p {
  font-size: 1.2rem;
  color: #fff;
}

#illustration-container {
  display: flex;
  /* background-color: #fff5dc; */
  /* height: 80vh; */
  width: 100%;
  bottom: 0;
  /* position: absolute; */
  align-items: center;
  justify-content: end;
}

#illustration-container .illustration {
  width: 90%;
  position: relative;
  z-index: 1;
}

.header-container {
  padding: 0 8vw;
  margin: 2vh 0;
  display: flex;
  justify-content: space-evenly;
}

#header-sec input {
  width: 100%;
  padding: 16px 20px;
  box-sizing: border-box;
  background-color: #2d2a66;
  color: white;
  border: none;
  border-radius: 50px;
  font-size: 1rem;
}
#header-sec input:focus {
  outline: none !important;
}

::placeholder {
  color: #8c97ac;
}

#header-content-box {
  z-index: 1;
  position: relative;
  width: 43vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#header-illustration-box {
  z-index: 1;
  position: relative;
  width: 43vw;
}

.content-glow {
  position: absolute;
  top: -5vh;
  width: 43vw;
  height: 43vw;
  left: -2vw;
  border-radius: 50%;
  /* background: red; */
  z-index: 1;
  background: linear-gradient(
    225.17deg,
    rgba(64, 0, 255, 0.2) 14.42%,
    rgba(130, 0, 255, 0.2) 36.17%,
    rgba(97, 0, 255, 0.2) 58.37%
  );
  filter: blur(129.597px);
  transform: rotate(-29.54deg);
}

#header-content-box p {
  font-size: 1.5rem;
  line-height: 3rem;
  font-weight: 400;
  color: #b5c2ff;
}

.store-row {
  margin-top: 8vh;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.store-btn {
  padding: 12px 0;
  background-color: black;
  width: 32%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 30px;
  box-shadow: 0px 0px 25px rgba(125, 41, 205, 0.3);
}
#chrome-ext-img {
  height: 90%;
}

.waitlist-row {
  margin-top: 4rem;
  display: flex;
  justify-content: space-between;
}

.waitlist-row div:first-child {
  width: 60%;
}
.waitlist-row div:last-child {
  width: 30%;
}

#header-sec h1 {
  font-size: 5rem;
  font-weight: 700;
}

/* Media Queries */

@media screen and (max-width: 1300px) and (orientation: portrait) {
  #navbar-items p {
    font-size: 0.9rem;
  }
  #header-sec h1 {
    font-size: 3rem;
  }
  /* #header-sec p {
        font-size: 1.2rem;
    } */
  #header-sec ::placeholder {
    font-size: 0.9rem;
  }
}
@media screen and (max-width: 1300px) and (orientation: landscape) {
  .header-container {
    margin-top: 15vh;
  }
  .header-text {
    font-size: 4.5rem;
  }
}

@media screen and (max-width: 1024px) {
  #header-sec {
    background-image: none;
    height: auto;
  }

  .logo {
    height: 60px;
  }

  #header-illustration-box {
    width: 100%;
  }
  #illustration-container {
    width: 80vw;
    /* height: 90vh; */
    left: 10%;
    bottom: 0;
    border-top-left-radius: 40vw;
    border-top-right-radius: 40vw;
  }
  #header-content-box {
    width: 100%;
    text-align: center;
  }
  .header-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  #funance-row {
    justify-content: center;
  }

  #illustration-container .illustration {
    width: 90%;
    z-index: 1;
  }

  .waitlist-row div:first-child {
    width: 100%;
  }
  .waitlist-row div:last-child {
    width: 100%;
  }

  .store-row {
    width: 75%;
    margin-top: 2vh;
    justify-content: space-between;
  }
  .store-btn {
    width: 45%;
  }
  #chrome-extension-btn {
    display: none;
  }

  .header-container {
    margin: auto;
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;
  }

  #header-sec input {
    width: 100%;
    padding: 20px 20px;
    margin: 8px 0 30px 0;
    box-sizing: border-box;
    background-color: white;
    border: none;
    border-radius: 20px;
    font-size: 1rem;
  }
  #header-sec input:focus {
    outline: none !important;
  }

  ::placeholder {
    color: #8c97ac;
  }

  .waitlist-row {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  #header-sec h1 {
    font-size: 2.6rem;
  }

  #header-sec p {
    font-size: 1rem;
    line-height: 2.2rem;
    color: white;
  }
}

@media screen and (max-width: 600px) {
  #navbar a {
    text-decoration: none;
    padding: 15px 0;
  }
  .store-row {
    width: 100%;
    justify-content: space-between;
  }

  #header-content-box {
    width: 100%;
    text-align: left;
  }
  #funance-row {
    justify-content: left;
  }
  #header-illustration-box {
    width: 100%;
  }

  .header-text {
    font-style: normal;
    font-weight: 700;
    font-size: 2.8rem;
    line-height: 4rem;
  }
  .logo {
    height: 30px;
  }

  #illustration-container {
    width: 80vw;
    height: auto;
    border-top-left-radius: 40vw;
    border-top-right-radius: 40vw;
    /* padding-top: 20px; */
    /* background: linear-gradient(#fff1cc, #fffaed); */
    align-items: flex-start;
  }

  #illustration-container .illustration {
    width: 100%;
    height: 50%;
    object-fit: scale-down;
    z-index: 1;
  }

  .waitlist-row div:first-child {
    width: 100%;
  }
  .waitlist-row div:last-child {
    width: 100%;
  }

  .header-container {
    flex-direction: column-reverse;
    margin-top: 8vh;
    justify-content: center;
  }

  #header-sec input {
    width: 100%;
    padding: 16px 20px;
    margin: 8px 0 30px 0;
    box-sizing: border-box;
    background-color: white;
    border: none;
    border-radius: 50px;
    font-size: 1rem;
  }

  .waitlist-row {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .store-btn {
    width: 48%;
  }
  #header-sec h1 {
    font-size: 2rem;
  }

  #header-sec p {
    font-size: 1rem;
    line-height: 1.5rem;
    color: white;
  }
}

@media screen and (max-width: 340px) {
  #header-sec {
    height: 150vh;
  }

  .store-btn {
    width: 48%;
  }
}
