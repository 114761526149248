#mobile-mockup-2 {
  width: 40vw;
}

#game-container {
  justify-content: center;
  margin-top: 4rem;
  text-align: left;
  height: 70vh;
}
#game-sec {
  height: 100vh;
  padding: 10vh 0;
}

.game-header {
  margin-top: 8rem;
  height: 100%;
  width: 40vw;
}

.game-header h1 {
  font-size: 2rem;
}

.game-header p {
  font-size: 1.5rem;
  line-height: 3.5rem;
}

#feature-sec-2 {
  height: 100vh;
  box-sizing: border-box;
  padding: 5vh 0px;
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background: linear-gradient(rgba(254, 134, 0, 0.1), #fff); */
}

#circle-base {
  display: flex;
  margin: auto;
  justify-content: center;
  border-radius: 50%;
  width: 45vh;
  height: 45vh;
  transform: rotateZ(0deg);
}

/* Media Queries */

@media screen and (max-width: 1300px) {
  #feature-sec-2 h1 {
    font-size: 1.5rem;
    text-align: left;
  }
  .game-header p {
    font-size: 1.1rem;
    line-height: 2.6rem;
  }
  #circle-base {
    height: 45vh;
    width: 45vh;
  }
}
@media screen and (max-width: 1024px) {
  #game-sec {
    height: auto;
  }
  #game-container {
    justify-content: center;
    margin-top: auto;
    text-align: left;
    height: 50vh;
  }
  #feature-sec {
    height: auto;
  }
  #feature-sec-2 {
    height: auto;
  }
  .game-header h1 {
    font-size: 1.7rem;
  }
  .game-header p {
    font-size: 1.1rem;
    line-height: 2.6rem;
  }
  #mobile {
    border-radius: 1.5rem;
  }

  #circle-base {
    height: 30vw;
    width: 30vw;
  }
  #mobile {
    border-radius: 1.5rem;
    width: 15vw;
    height: 30vw;
  }
}
@media screen and (max-width: 600px) {
  #feature-sec-2 {
    padding: 8vh 0;
  }
  #feature-sec-2 h1 {
    text-align: center;
  }

  #mobile-mockup-2 {
    width: 100%;
  }

  #game-container {
    display: flex;
    flex-direction: column-reverse;
    height: auto;
    justify-content: space-evenly;
    margin: 2rem 0px 8vh 0px;
    padding: 0 20px 0 20px;
    text-align: center;
  }

  .game-header {
    margin-top: 4rem;
    width: 100%;
    text-align: left;
  }
  #game-container h1 {
    font-size: 2rem;
  }

  .game-header p {
    font-size: 1.25rem;
    line-height: 2.5rem;
  }

  #circle-base {
    width: 75vw;
    height: 75vw;
    transform: rotateZ(0deg);
  }
  #mobile {
    margin-top: 4rem;
    height: 75vw;
    width: 38vw;
    border-radius: 1.2rem;
    background-color: white;
    box-shadow: 40px 40px 100px 0px #7453001a;
  }
}
