#feature-sec {
  height: 100vh;
}
#brand-row {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 5vh;
}
#and-more {
  font-weight: 700;
  font-size: 24px;
  line-height: 31px;
  text-transform: lowercase;
  color: #b5c2ff;
}

#feature-posts-wrapper-style {
  justify-content: center;
  flex-direction: row;
  align-items: center;
  height: 70vh;
  padding: 5vh 0;
}

#feat-img-container {
  width: 80%;
  padding-right: 5vw;
}
#brand-col {
  text-align: center;
}
.brand-header {
  font-weight: 600;
  font-size: 1.6rem;
}
/* Media Queries */

@media screen and (max-width: 1300px) {
}
@media screen and (max-width: 1024px) {
  #brand-col {
    padding: 0 5vw;
  }
  #brand-row {
    margin-top: 2vh;
    justify-content: space-evenly;
  }
  #feature-posts-wrapper-style {
    height: auto;
  }
}
@media screen and (max-width: 600px) {
  #feature-posts-wrapper-style {
    flex-direction: column;
    height: auto;
  }
  #feat-img-container {
    width: 85%;
    padding: 0 5vw;
  }
  #brand-col {
    margin: 5vh 0;
  }
  #brand-row {
    display: flex;
    flex-wrap: wrap;
    margin-top: 2vh;
  }
  .brand-header {
    font-size: 1.5rem;
  }

  #and-more {
    font-size: 0.9rem;
    line-height: 1.1rem;
  }

  .brand-item {
    margin: 20px 0px;
    height: 50px;
    text-align: center;
  }
  .brand-item img {
    height: 50px;
    width: 100px;
    object-fit: contain;
  }
}
