#team-sec {
  height: 100vh;
  width: 90%;
  margin: auto;
  text-align: center;
  box-sizing: border-box;
  padding: 5vh 0px;
  /* background-color: #fff; */
}

#team-sec h1 {
  font-size: 2rem;
}
.team-avatar {
  display: flex;
  width: 100%;
  justify-content: center;
  position: relative;
  top: -22%;
}

#team-base-circle {
  height: 170px;
  width: 170px;
  text-align: center;
  display: flex;
  justify-content: center;
}
#team-base-circle .nested-circle {
  height: 170px;
  width: 170px;
}

#team-base-circle .identity {
  position: relative;
  top: -15%;
}

.identity h1 {
  color: black;
}
#team-base-circle h1 {
  font-size: 1rem;
  margin: 0;
}
#team-base-circle p {
  font-size: 0.7rem;
  margin: 0 5px;
  padding-top: 2px;
  color: rgba(0, 213, 170, 1);
}

@media screen and (max-width: 1300px) {
  #team-sec h1 {
    font-size: 1.5rem;
  }

  #team-base-circle .identity h1 {
    font-size: 1rem;
  }
}

@media screen and (max-width: 1024px) {
  #team-sec {
    height: auto;
  }
}
