#website-wrapper {
  background: linear-gradient(
    110deg,
    #13001c 9.09%,
    rgba(26, 3, 58, 0.921308) 20%,
    rgba(25, 7, 66, 0.834476) 31%,
    rgba(42, 12, 132, 0.73) 45%,
    rgba(61, 30, 152, 0.73) 57%,
    rgba(42, 12, 132, 0.73) 70%
  );
  position: relative;
  /* overflow: hidden; */
}

.animate-star {
  position: absolute;

  background: transparent;
  background-color: white;
  border-radius: 50%;
  opacity: 0.7;
  z-index: 1;
  /* box-shadow: $shadows-small; */
  animation: animateStar 100s infinite linear;
}
.twinkle {
  animation: twinkle 10s infinite ease-in-out;
}

@keyframes animateStar {
  0% {
    transform: scale(1) translate(0, 0px);
    opacity: 0.5;
  }
  25% {
    transform: scale(2) translate(100px, 50px);
    opacity: 0.2;
  }
  75% {
    transform: scale(2) translate(-100px, -50px);
    opacity: 0.5;
  }
  100% {
    transform: scale(1) translate(-100px, 0px);
    opacity: 0.2;
  }
}
@keyframes twinkle {
  0% {
    opacity: 1;
    transform: rotate(0);
  }
  50% {
    opacity: 0;
    transform: rotate(90deg);
  }
  100% {
    opacity: 1;
    transform: rotate(180deg);
  }
}

@media screen and (max-width: 1024px) and (orientation: portrait) {
  #website-wrapper {
    overflow: hidden;
  }
}
@media screen and (max-width: 600px) {
  #website-wrapper {
    overflow: hidden;
  }
}
